export default {
  primary1: "#FFF8FB",
  primary2: "#FCDEEC",
  primary3: "#F7ABCE",
  primary4: "#EF60A3",
  primary5: "#960051",
  neutral1: "#FFFFFF",
  neutral2: "#E8E8E8",
  neutral3: "#DDDDDD",
  neutral4: "#ACACAC",
  neutral5: "#7A7A7A",
  neutral6: "#4A4A4A",
  neutral7: "#000",
  red1: "#FBE6E9",
  red2: "#F12D3E",
  red3: "#DC061E",
  red4: "#B00B13",
  orange1: "#FCEDE0",
  orange2: "#FC9849",
  orange3: "#E76600",
  orange4: "#D74800",
  green1: "#E6F6F0",
  green2: "#1ACD64",
  green3: "#09A969",
  green4: "#059669",
};
