import { React, useEffect, useState } from "react";
import "../../css/shop_order_summary.css";
import checkGreen from "../../../../assets/img/pynk/shop/Subtract.png";
import { useLocation, useHistory } from "react-router-dom";

const PayPassPaymentError = () => {
  const history = useHistory();
  const { pathname } = useLocation(null);
  const data = localStorage.getItem("dataUserPersist");
  const feedData = JSON.parse(data);

  const [isRefresh, setIsRefresh] = useState(false);

  const goBack = () => {
    history.replace("/home");
    localStorage.removeItem("dataUserPersist");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    // protect route when refresh page
    const handleBeforeUnload = () => {
      setIsRefresh(true);
    };

    if (isRefresh) {
      localStorage.removeItem("dataUserPersist");
      window.location.replace("/home");
    }

    if (feedData == null) {
      window.location.replace("/home");
    }

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("unload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("unload", handleBeforeUnload);
    };
  }, [isRefresh]);

  return (
    <div className="box-order-summary">
      <div className="background-order-summary row">
        <div className="position-relative justify-content">
          <div className="box-promptPay">
            <div className="col-12 col-md-10  col-lg-7">
              <div className="order-details background-promptPay">
                <div className="justify-content">
                  <img src={checkGreen} className="check-success-img" />
                </div>
                <p className="text-head-order-summary text-center">
                  ชำระเงินไม่สำเร็จ
                </p>
                <p className="text-center order-number">
                  {"หมายเลขคำสั่งซื้อ " +
                    feedData?.value_selected_regis?.productName +
                    " " +
                    feedData?.value_selected_regis?.day}
                </p>
                <p className="text-head-order-summary  between">
                  รายละเอียดการสั่งซื้อ{" "}
                </p>
                <p className="text-order">
                  ชื่อ {feedData?.formData?.username} นามสกุล{" "}
                  {feedData?.formData?.surname}
                </p>
                <p className="text-order">{feedData?.formData?.email}</p>
                <p className="text-order">
                  {feedData?.formData?.phone_number &&
                    feedData?.formData?.phone_number.slice(0, 3) +
                      "-" +
                      feedData?.formData?.phone_number.slice(3, 6) +
                      "-" +
                      feedData?.formData?.phone_number.slice(6)}
                </p>
                <div className="line-hr-order" />
                <p className="text-head-order-summary">สรุปรายการสั่งซื้อ</p>

                <div className="text-order between">
                  <p className="col-8">
                    {"PYNK PAY PASS " + feedData?.value_selected_regis?.day}
                  </p>
                  <p>
                    {feedData?.value_selected_regis?.price.toLocaleString() +
                      " บาท"}
                  </p>
                </div>

                <p className="text-order between">
                  ค่าจัดส่ง <span className="text-head-order-summary">ฟรี</span>
                </p>
                <div className="line-hr-order" />
                <p className="between text-order">
                  วิธีชำระเงิน
                  {feedData?.payment === "qr_code" && <span>QR Code</span>}
                  {feedData?.payment === "credit_card" && (
                    <span>Credit Card</span>
                  )}
                </p>
                <p className="amount-be-paid between">
                  ยอดที่ต้องชำระ{" "}
                  <span>
                    {feedData?.value_selected_regis?.price.toLocaleString() +
                      " บาท"}
                  </span>
                </p>
              </div>
              <p className="text-center please-contact">
                หากต้องการแก้ไขข้อมูลการจัดส่ง กรุณาติดต่อ 02-xxx-xxxx
              </p>
              <div className="justify-content">
                <div className="col-12 col-md-8">
                  <button
                    type="submit"
                    className="btn-buy-payment"
                    onClick={goBack}
                  >
                    กลับหน้าแรก
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayPassPaymentError;
