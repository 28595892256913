import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Modal, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from "react-redux";
import { updateModalDoneClicked, clearStatusCardActivities, getCardActivities } from "../../../../redux/pynk/contents";
import "../paypass.scss";
import play_pass_done from "../../../../assets/img/play_pass_done.png";
import { height } from '@mui/system';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  height: 300,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
  textAlign: 'center',
  outline: 'none'
};

const DialogVideoDone = ({ isAllVideoDone, setDialog }) => {
  const [open, setOpen] = useState(false);
  const user = useSelector(({ auth }) => auth && auth.user);
  const dispatch = useDispatch();

  const { cardActivities, card_id, index_card , status_update_modal_done_clicked , status_card_act} = useSelector(
    ({ contents }) => contents && contents
  );

  const cardSelected = cardActivities.filter(
    (item) => item.card_id == card_id
  );
  const jsonData = JSON.parse(cardSelected[0]?.card_activities || "[]");
  const act_id = jsonData.filter((item, i) => i + 1 == index_card)[0]?.id_video;

  const { modal_done_status } = useSelector(({ getPynk }) => getPynk && getPynk);

  console.log(status_update_modal_done_clicked);

  React.useEffect(() => {
    if(status_update_modal_done_clicked == 'success'){
      dispatch(getCardActivities(user && user.user_id));
    }
  }, [status_update_modal_done_clicked]);

  React.useEffect(() => {
    if(status_card_act == 'success'){
      dispatch(clearStatusCardActivities());
    }
  }, [status_card_act]);

  const handleSaveModal = () => {
    dispatch(updateModalDoneClicked(user.user_id, act_id));
    setOpen(false);
  };

  const handleClose = () => {
    setDialog('no');
    setOpen(false);
  }

  useEffect(() => {
    if (isAllVideoDone && modal_done_status !== 'yes') {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [isAllVideoDone, modal_done_status]);

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={style}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <img
            src={play_pass_done}
            style={{
              width: 136,
              height: 136,
              marginTop: '-65px'
            }}
            alt="img"
          />
          <Typography id="modal-title" variant="h6" component="h2" fontWeight="700">
            เยี่ยมมาก! ออกกำลังกายได้ครบแล้ว
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }} fontWeight="700">
            ต้องการบันทึกหรือไม่
          </Typography>
          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-around' }}>
            <button
              className="button_pink_outline"
              style={{
                width: 240,
                height: 44,
                marginTop: 27,
                marginBottom: 91,
              }}
              onClick={handleClose}
            >
              ปิด
            </button>
            <button
              className="button_pink"
              style={{
                width: 240,
                height: 44,
                marginTop: 27,
                marginBottom: 91,
              }}
              onClick={handleSaveModal}
            >
              บันทึก
            </button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default DialogVideoDone;
