import { Box, Container, Grid, Stack, Switch, Typography } from "@mui/material";
import * as React from "react";
import "../paypass.scss";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import chipIcon from "../../../../assets/img/pynk/chip.png";
import icon_play from "../../../../assets/img/pynk/icon_play.png";
import titleBg from "../../../../assets/img/pynk/Title.png";
import DialogVideo from "./DialogVideo";
import DialogVideoDone from "./DialogVideoDone";
import {
  Timeline,
  TimelineConnector,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  convertFormatTime,
  convertSecondsToMinutes,
} from "../../../../helpers/utils";
import { getCardActivities } from "../../../../redux/pynk/contents";
import { getModalDoneStatus } from "../../../../redux/pynk/get";

export default function GenerateProgram() {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth && auth.user);
  const { cardActivities, card_id, index_card } = useSelector(
    ({ contents }) => contents && contents
  );
  const cardSelected = cardActivities.filter(
    (item) => item.card_id == card_id
  );
  const jsonData = JSON.parse(cardSelected[0]?.card_activities || "[]");
  const act_id = jsonData.filter((item, i) => i + 1 == index_card)[0]?.id_video;
  const { modal_done_status } = useSelector(({ getPynk }) => getPynk && getPynk);

  const [openDialogVideo, setOpenDialogVideo] = React.useState(false);
  const [dialog, setDialog] = React.useState('yes');

  const [isAutoPlay, setIsAutoPlay] = React.useState(false);
  const [totalTime, setTotalTime] = React.useState(0);
  const [videos, setVideos] = React.useState([]);
  const [videoUrl, setVideoUrl] = React.useState("");
  const isAllVideoDone = videos.every(activity => activity.play_time !== 0);

  const handleClickOpenVideo = (url) => {
    setOpenDialogVideo(true);
    setVideoUrl(url);
  };

  const handleCloseVideo = () => {
    dispatch(getCardActivities(user && user.user_id));
    setOpenDialogVideo(false);
  };

  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (user == null) {
      //protect route
      window.location.replace("/home");
    }
    dispatch(getModalDoneStatus(user && user.user_id, act_id));
  }, []);

  React.useMemo(() => {
    if (cardActivities && cardActivities.length > 0) {
      const cardSelected = cardActivities.filter(
        (item) => item.card_id == card_id
      );
      const jsonData = JSON.parse(cardSelected[0]?.activities_vdo || "[]");
      const data = jsonData[index_card - 1];

      setVideos(data.activity || []);
      setDialog(data.modal_done_clicked);
    }

    if (!isAutoPlay) {
      setVideoUrl("");
    }
  }, [cardActivities, isAutoPlay]);

  React.useEffect(() => {
    if (videos.length == 0) {
      dispatch(getCardActivities(user && user.user_id));
    }
    if (videos.length > 0) {
      const calculateTotalTime =
        videos.reduce((acc, val) => {
          return acc + val.duration;
        }, 0) / 60;
      setTotalTime(calculateTotalTime);
    }
  }, [videos]);

  return (
    <div style={{ background: "#DDDDDD", paddingBottom: "60px" }}>
      <Box
        sx={{
          backgroundImage: `url(${titleBg})`,
          height: "100%",
          backgroundSize: "cover",
          paddingTop: "72px",
          paddingBottom: "72px",
        }}
      >
        <Typography
          align="center"
          fontWeight={600}
          sx={{ fontSize: { xs: "42px", sm: "52px" } }}
        >
          <span style={{ color: "#EF60A3" }}>PYNK</span> PAY PASS
        </Typography>
      </Box>

      <Container
        maxWidth={"xl"}
        sx={{
          background: "white",
          paddingLeft: "-0px !important",
          paddingRight: "-0px !important",
        }}
      >
        <Stack
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
          sx={{
            height: "102px",
            background: "#FFF8FB",
            padding: "0 23px",
          }}
        >
          <Typography>รวมเวลาฝึกทั้งหมด {totalTime.toFixed(2)} นาที</Typography>
          <Stack
            flexDirection={"row"}
            alignItems={"center"}
            justifyContent={"center"}
            gap={1}
          >
            <Typography>เล่นอัตโนมัติ</Typography>
            <Switch
              sx={{
                "& .MuiSwitch-switchBase.Mui-checked": {
                  color: "#ED0876",
                },
                "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                  backgroundColor: "#ED0876",
                },
              }}
              value={isAutoPlay}
              onChange={(e) => setIsAutoPlay(e.target.checked)}
            />
          </Stack>
        </Stack>

        <Grid container sx={{ marginTop: "64px" }}>
          <Grid item xs={3} sm={2} md={1}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              alignItems={"center"}
            >
              <Typography color={"#ED0876"} fontWeight={500}>
                เริ่มกันเลย
              </Typography>

              {videos.map((item, index) => {
                const isLastIndex = index == videos.length - 1;
                const isVideoDone = item.play_time > 0;
                return (
                  <Timeline sx={{ ml: -5 }} key={index}>
                    <TimelineItem>
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{
                            width: 30,
                            height: 30,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            background: isVideoDone ? "#EF60A3" : "#DDDDDD",
                            mt: isLastIndex ? "0px" : 0.1,
                            ml: isLastIndex ? 1 : 0,
                          }}
                        >
                          <Typography color={isVideoDone ? "white" : "#7A7A7A"}>{index + 1}</Typography>
                        </TimelineDot>
                        {isLastIndex ? (
                          null
                        ) : (
                          <TimelineConnector
                            sx={{
                              width: 7,
                              height: { xs: 270, md: 143 },
                              borderRadius: 16,
                              background: isVideoDone ? "#EF60A3" : "#DDDDDD",
                            }}
                          />
                        )}
                        {isLastIndex && isAllVideoDone &&
                          <Typography color="#EF60A3" fontWeight={500}>
                            สำเร็จ!
                          </Typography>
                        }

                      </TimelineSeparator>
                    </TimelineItem>
                  </Timeline>
                );
              })}
            </Box>
          </Grid>
          <Grid item xs={9} sm={10} md={11}>
            {videos.map((item, index) => {
              const minuteLabel =
                item?.duration < 20
                  ? convertFormatTime(item.duration)
                  : convertSecondsToMinutes(item.duration);
              return (
                <Grid container sx={{ marginBottom: "48px" }} key={index}>
                  <Grid item xs={12} sm={12} md={4} lg={2.5}>
                    <div
                      style={{
                        backgroundImage: `url(${item.thumbnail})`,
                      }}
                      className="header_img_video"
                    >
                      <img
                        src={icon_play}
                        style={{
                          width: 64,
                          height: 64,
                          cursor: "pointer",
                        }}
                        alt="img"
                        onClick={() => handleClickOpenVideo(item.url3 || "")}
                      />
                    </div>
                  </Grid>

                  <Grid item xs={12} sm={12} md={8} lg={9.5}>
                    <Box
                      sx={{
                        marginLeft: { xs: 0, lg: "48px" },
                        display: "flex",
                        justifyContent: "space-between",
                        flexDirection: "column",
                        height: "100%",
                      }}
                    >
                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={1}
                        sx={{
                          marginTop: { xs: 2, lg: 0 },
                        }}
                      >
                        <AccessAlarmIcon sx={{ color: "#828282" }} />
                        <Typography color={"#828282"}>
                          {minuteLabel} นาที
                        </Typography>
                      </Stack>
                      <Typography
                        color={"#ED0876"}
                        fontSize={"32px"}
                        fontWeight={600}
                      >
                        {item.name}
                      </Typography>
                      <Stack
                        flexDirection={"row"}
                        alignItems={"center"}
                        gap={1}
                      >
                        <Typography color={"#828282"}>
                          สัดส่วนที่ได้ :
                        </Typography>
                        <img src={chipIcon} style={{ height: "40px" }} />
                      </Stack>
                    </Box>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Container>
      {(isAllVideoDone && dialog == 'no')&&
        <DialogVideoDone
          isAllVideoDone={isAllVideoDone}
          setDialog={setDialog}
        />
      }
      <DialogVideo
        openDialogVideo={openDialogVideo}
        handleCloseVideo={handleCloseVideo}
        videoUrl={videoUrl}
        isAutoPlay={isAutoPlay}
        videos={videos}
        setVideoUrl={setVideoUrl}
      />
    </div>
  );
}
