import {
  Avatar,
  Box,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import * as React from "react";
import bestsellerIcon from "../../../assets/img/pynk/bestseller.png";
import icon_circle from "../../../assets/img/pynk/shop/icon-circle.png";
import Slider from "react-slick";
import { Link, useHistory } from "react-router-dom";
import "./paypass.scss";
import eightWeek_img from "../../../assets/img/pynk/8week_img.png";
import twentyHit_img from "../../../assets/img/pynk/20hiit_img.png";
import sevenDay_img from "../../../assets/img/pynk/7day_img.png";
import better_img from "../../../assets/img/pynk/better_img.png";
import getfit_img from "../../../assets/img/pynk/getfir_img.png";
import fitWith_img from "../../../assets/img/pynk/fitwith_img.png";
import financial_img from "../../../assets/img/pynk/financial_img.png";
import popular_img from "../../../assets/img/pynk/popular_img.png";
import video_img from "../../../assets/img/pynk/video_img.png";
import rectangle4424 from "../../../assets/img/pynk/rectangle-4424.png";
import mockup from "../../../assets/img/pynk/mockup.png";
import a1 from "../../../assets/img/pynk/a1.png";
import a2 from "../../../assets/img/pynk/a2.png";
import a3 from "../../../assets/img/pynk/a3.png";
import best_seller from "../../../assets/img/pynk/best_seller.png";
import { useDispatch, useSelector } from "react-redux";
import {
  buyCardCollectPoints,
  getCardActivities,
  selectedValueRegister,
} from "../../../redux/pynk/contents";

const imgBanner = [
  { src: eightWeek_img },
  { src: twentyHit_img },
  { src: sevenDay_img },
  { src: better_img },
  { src: getfit_img },
  { src: fitWith_img },
];

const priceCard = [
  {
    day: "3 วัน",
    price: "1",
    number_of_days: "3",
    productName: "PYNK PAY PASS",
  },
  {
    day: "7 วัน",
    price: "1",
    number_of_days: "7",
    productName: "PYNK PAY PASS",
  },
  {
    day: "10 วัน",
    price: "1",
    number_of_days: "10",
    productName: "PYNK PAY PASS",
  },
];

export default function PayPassSale() {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth && auth.user);
  const { cardActivities, status_buy_card_collection } = useSelector(
    ({ contents }) => contents && contents
  );
  const [selectedValue, setSelectedValue] = React.useState("7");
  const [selectedCard, setSelectedCard] = React.useState(priceCard[1]);
  const contentRef = React.useRef(null);

  const handleSlideRegister = () => {
    if (contentRef.current) {
      contentRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const handleClickSelectBox = (item) => {
    console.log("item", item);


    /*  setSelectedCard(item);
     const daysMap = {
       5: "5",
       7: "7",
       10: "10",
     };
     setSelectedValue(item);
     dispatch(selectedValueRegister(selectedCard)); */
  };

  const handleChangeValues = (event) => {
    setSelectedValue(event.target.value);
  };

  const PreviousBtn = (props) => {
    const { onClick, currentSlide } = props;
    return (
      <>
        <div className={`previous-btn-pay`} onClick={onClick}>
          <img src={icon_circle} className="icon-previous-btn" />
        </div>
      </>
    );
  };
  const NextBtn = (props) => {
    const { onClick, slideCount, currentSlide } = props;
    return (
      <>
        <div className={`next-btn-pay`} onClick={onClick}>
          <img src={icon_circle} className="icon-next-btn" />
        </div>
      </>
    );
  };

  const settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    slidesToShow: 1,
    slidesToScroll: 1,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 9000,
  };

  const handleRegisterProgram = (item) => {
    if (user == null || user?.user_id == "") return history.replace("/login");
    history.replace("/paypass_payment");




    setSelectedCard(item);
    const daysMap = {
      5: "5",
      7: "7",
      10: "10",
    };
    setSelectedValue(item);
    dispatch(selectedValueRegister(item));
  };

  React.useEffect(() => {
    if (contentRef.current && history.location.search == "?slideSale=true") {
      contentRef.current.scrollIntoView({ behavior: "smooth" });
    }
    dispatch(getCardActivities(user && user.user_id));
  }, [user?.user_id]);


  const scrollToPricing = () => {
    const pricingSection = document.querySelector('.pricing-container');
    const yOffset = -180; // คุณสามารถปรับ offset ได้หากต้องการเลื่อนให้ห่างจากจุดเริ่มต้น
    const y = pricingSection.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({
      top: y,
      behavior: 'smooth' // การเลื่อนแบบ smooth
    });
  };


  return (
    <div>

      <div className="box-head-pass head-pass-img-box"></div>
      <div className="head-pass-text-box">
        <p className="text-hade-play-pass"><span>PYNK</span> PLAY PASS</p>
        <p className="text-play-pass">อิสระใหม่ของการออกกำลังกาย</p>
        <p className="text-play">เบื่อไหม? กับโปรแกรมออกกำลังกายที่ต้องฝึกตามตลอดเวลาทุกวัน  กับ PYNK Play Pass คุณจะได้อิสระในการเลือกเล่นคลิปออกกำลังกายที่คุณชอบได้ตามใจ ไม่ว่าคุณต้องการฝึกเมื่อไหร่ ที่ไหน แบบไหนก็ได้เลือกได้ตามต้องการ</p>
        <button className="button_pink_pass mb-5" onClick={scrollToPricing} >สมัคร</button>
      </div>
      <div className="big-box-pass">
        <div className="pink_pass-cat">
          <p className="text-head-cat mb-4" >ทำไมต้องเลือก PYNK Play Pass?</p>
          <div className="row mb-3">
            <div className="col-sm-12 col-md-2 col-xl-1 mb-4">
              <img src={popular_img} className="popular_img" />
            </div>
            <div className="col-sm-12 col-md-10 col-xl-11">
              <p className="pink_pass-cat-p">
                <span>อิสระเต็มที่:</span> คุณสามารถเลือกดูคลิปออกกำลังกายจาก คลังวิดีโอของเราที่มีหลากหลายสไตล์ เลือกเล่นตามอารมณ์ ไม่ว่าจะเป็นคาร์ดิโอแบบเบิร์นหนักๆ หรือยืดเส้นเบาๆ ให้คุณได้ออกกำลังกายอย่างสนุกและไม่รู้สึกเบื่อ
              </p>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-sm-12 col-md-2 col-xl-1 mb-4">
              <img src={financial_img} className="popular_img" />
            </div>
            <div className="col-sm-12 col-md-10 col-xl-11">
              <p className="pink_pass-cat-p">
                <span>ไม่มีข้อผูกมัด:</span> ไม่ต้องกังวลว่าจะต้องทำตามโปรแกรมยาวๆ ถ้าไม่ถูกใจ! PYNK Play Pass ให้คุณได้ลองเล่นคลิปต่างๆ
                เพื่อค้นหาสไตล์ที่ใช่ ก่อนที่จะตัดสินใจสมัครโปรแกรมแบบเต็มๆ
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 col-md-2 col-xl-1 mb-4">
              <img src={video_img} className="popular_img" />
            </div>
            <div className="col-sm-12 col-md-10 col-xl-11">
              <p className="pink_pass-cat-p">
                <span>เข้าถึงได้ทุกอย่าง:</span>Pass นี้จะพาคุณเข้าถึงทุกคลิปวิดีโอจาก ทุกโปรแกรมที่เรามี ไม่ว่าจะเป็น <span>"better shape"</span>
                หรือ <span>"fit with plant"</span> ให้คุณสนุกไปกับการออกกำลังกาย แบบไม่มีขีดจำกัด
              </p>
            </div>
          </div>
        </div>

      </div>
      <div className="idea-behind">
        <div className="row">
          <div className="col-md-6">
            <p className="idea-text-head">
              แนวคิดเบื้องหลัง PYNK Play Pass
            </p>
            <p className="idea-text">จากการสำรวจและฟังเสียงลูกค้า เราพบว่าคุณให้ความสำคัญ
              กับเนื้อหาวิดีโอเป็นหลัก และต้องการอิสระในการเลือกเล่นคลิปต่างๆ ตามที่คุณชอบ
              นั่นคือเหตุผลที่เราสร้าง PYNK Play Pass ขึ้นมา เพื่อตอบโจทย์
              ความต้องการของคุณ โดยไม่ต้องถูกจำกัดด้วยโปรแกรมที่กำหนดมาแล้ว</p>
          </div>
          <div className="col-md-6">
            <img src={rectangle4424} className="rectangle4424" />

          </div>
        </div>
      </div>

      <div className="easy-use-box">
        <div className="row">
          <div className="col-md-6">
            <img src={mockup} className="mockup" />

          </div>
          <div className="col-md-6">
            <p className="text-head-mockup">
              ใช้งานง่ายๆ ได้ตามใจคุณ
            </p>
            <ul>
              <li className="text-mockup"><span>เลือก Pass ที่เหมาะกับคุณ:</span> ไม่ว่าจะเป็น Pass ตามจำนวนครั้ง หรือระยะเวลา คุณก็เลือกได้ตามสะดวก</li>
              <li className="text-mockup"><span>สำรวจตามใจ:</span> เข้าถึงคลิปในคลังของเราได้ทุกคลิปทุกโปรแกรม เลือกลองเล่นและค้นหาสไตล์ที่เหมาะกับคุณ</li>
              <li className="text-mockup"><span>ค้นพบสิ่งที่คุณชอบ:</span> ลองคลิปออกกำลังกายใหม่ๆ และค้นหาว่าอะไรที่ใช่สำหรับคุณ ก่อนที่จะตัดสินใจลงทุนกับโปรแกรมเต็มรูปแบบ</li>
            </ul>

          </div>
        </div>
      </div>
      <div className="advantages-box">
        <p className="text-advantages">ข้อได้เปรียบที่คุณต้องลอง PYNK Play Pass ไม่ใช่แค่โปรแกรมออกกำลังกายทั่วไป
          แต่มันคือการเปลี่ยนแปลงวิธีที่คุณออกกำลังกาย:</p>


        <div className="advantages-container">
          <div className="advantages">
            <img src={a1} alt="Image 1" className="advantages-image" />
            <p className="advantages-title">ราคามิตรภาพ ไม่ซับซ้อน:</p>
            <p className="advantages-text">ไม่มีค่าใช้จ่ายแอบแฝง คุณสามารถเข้าถึงคลิปทั้งหมดในคลังของเราได้ทันที</p>
          </div>

          <div className="advantages">
            <img src={a2} alt="Image 2" className="advantages-image" />
            <p className="advantages-title">ปรับให้เหมาะกับคุณ:</p>
            <p className="advantages-text">ไม่ว่าจะเป็นการออกกำลังกายจริงจังหรือแค่สนุก PYNK Play Pass ให้คุณเลือกเล่นได้ตามที่ต้องการ</p>
          </div>

          <div className="advantages">
            <img src={a3} alt="Image 3" className="advantages-image" />
            <p className="advantages-title">อัปเดตคลิปใหม่ๆ ตลอดเวลา:</p>
            <p className="advantages-text">เพิ่มคลิปใหม่ๆ เข้ามาอย่างต่อเนื่องและสามารถเข้าถึงได้ทันที โดยไม่ต้องเสียค่าใช้จ่ายเพิ่ม</p>
          </div>
        </div>
      </div>
      <div>
        <p className="program-text-head">โปรแกรมออกกำลังกายออนไลน์</p>
        <div className="program-container">
          {imgBanner && imgBanner.map((item, i) => (
            <div className="program">
              <img
                src={item.src}
                alt={item.src}
                className="program-image"
              />
            </div>
          ))}
        </div>

      </div>
      <div className="pricing-container-box">
        <p className="text-select-pass">เลือก Pass ที่เหมาะกับคุณ</p>
        <p className="text-select">ไม่ว่าจะเป็น Pass ตามจำนวนครั้ง หรือระยะเวลา คุณก็เลือกได้ตามสะดวก</p>
        <div className="pricing-container">
          {
            priceCard && priceCard.map((item, i) => (
              <>
                {item.number_of_days == "3" && (
                  <div className="pricing-box" key={i}>
                    <p className="program-title">เลือกโปรแกรมออกกำลังกาย</p>
                    <h2 className="program-count">3 ครั้ง</h2>
                    <h3 className="price">299 <span>บาท</span></h3>
                    <button className="btn-signup" onClick={() => handleRegisterProgram(item)}>สมัคร</button>
                  </div>
                )}

                {item.number_of_days == "7" && (
                  <div className="pricing-box" key={i}>
                    <img src={best_seller} alt="Best Seller" className="best_seller" />
                    <p className="program-title">เลือกโปรแกรมออกกำลังกาย</p>
                    <h2 className="program-count">7 ครั้ง</h2>
                    <h3 className="price">599 <span>บาท</span></h3>
                    <button className="btn-signup" onClick={() => handleRegisterProgram(item)}>สมัคร</button>
                  </div>
                )}

                {item.number_of_days == "10" && (
                  <div className="pricing-box" key={i}>
                    <p className="program-title">เลือกโปรแกรมออกกำลังกาย</p>
                    <h2 className="program-count">10 ครั้ง  <span className="plus-extra">แถมเพิ่ม 1 ครั้ง</span></h2>
                    <h3 className="price">999 <span>บาท</span></h3>
                    <button className="btn-signup" onClick={() => handleRegisterProgram(item)}>สมัคร</button>
                  </div>
                )}
              </>
            ))
          }
        </div>
      </div>

      <div className="shutterstock-footer">
        <div className="shutterstock-text-box">
          <p className="shutterstock-text-1">อย่าพลาดโอกาสในการควบคุมเส้นทางการออกกำลังกายของคุณเองกับ PYNK Play Pass </p>
          <p className="shutterstock-text-2">คุณไม่ได้แค่ซื้อโปรดักส์ แต่คุณกำลังลงทุนในไลฟ์สไตล์ที่ให้คุณได้เลือกสนุก และออกกำลังกายได้ตามใจต้องการ</p>
          <p className="shutterstock-text-3">ซื้อ PYNK Play Pass ของคุณวันนี้เลยเริ่มสำรวจ ลองเล่น และสนุกไปกับอิสระที่คุณเลือกได้เอง </p>
        </div>
      </div>



      <Box
        sx={{
          background: "#DDDDDD",
          paddingTop: "91px",
          paddingBottom: "108px",
        }}
      >
        <Stack flexDirection={"column"} alignItems={"center"}>
          <Typography
            sx={{
              fontSize: { xs: "26px", sm: "32px" },
              fontWeight: 500,
              padding: { xs: "16px", sm: 0 },
            }}
          >
            เสียงตอบรับจากผู้ใช้โปรแกรมออกกำลังกาย
          </Typography>

          <Rating
            name="read-only"
            value={5}
            readOnly
            sx={{ fontSize: 40, color: "#EF60A3", marginTop: "16px" }}
          />
        </Stack>
        <Container maxWidth="md" sx={{ marginTop: "48px" }}>
          <Slider {...settings}>
            <div>
              <div style={{ width: "99.5%" }}>
                <Paper
                  sx={{
                    padding: "30px",
                    borderRadius: "16px",
                    height: "100%",
                  }}
                >
                  <Stack flexDirection={"row"} gap={2}>
                    <Avatar sx={{ bgcolor: '#ff69b4', color: '#fff', fontWeight: 'bold', fontSize: '32px', width: '64px', height: '64px' }}>
                      N
                    </Avatar>
                    <Typography sx={{ fontSize: '24px', marginTop: '16px' }}>Niphaphon N</Typography>
                  </Stack>
                  <Typography mt={"24px"}>
                    *คอร์สช่วยตอบโจทย์การออกกําลังกายค่ะ โดยรวมตามระยะ
                    เวลาที่ร่วม กล้ามเนื้อค่อนข้างกระชับทุกสัดส่วน อยากให้เพิ่ม เติมกล้ามเนื้อมัดเล็กๆ ค่ะ เพราะของคอร์สจะเน้นกล้ามเนื้อมัด ใหญ่ เป็นหลัก ส่วนอาหารจริงๆ เป็นไอเดียได้ในบางส่วน ส่วน ใหญ่จะทำทานเองมากกว่า
                  </Typography>
                </Paper>
              </div>
            </div>
            <div>
              <div style={{ width: "99.5%" }}>
                <Paper
                  sx={{
                    padding: "30px",
                    borderRadius: "16px",
                    height: "100%",
                  }}
                >
                  <Stack flexDirection={"row"} gap={2}>
                    <Avatar sx={{ bgcolor: '#ff69b4', color: '#fff', fontWeight: 'bold', fontSize: '32px', width: '64px', height: '64px' }}>
                      N
                    </Avatar>
                    <Typography sx={{ fontSize: '24px', marginTop: '16px' }}>Niphaphon N</Typography>
                  </Stack>
                  <Typography mt={"24px"}>
                    *คอร์สช่วยตอบโจทย์การออกกําลังกายค่ะ โดยรวมตามระยะ
                    เวลาที่ร่วม กล้ามเนื้อค่อนข้างกระชับทุกสัดส่วน อยากให้เพิ่ม เติมกล้ามเนื้อมัดเล็กๆ ค่ะ เพราะของคอร์สจะเน้นกล้ามเนื้อมัด ใหญ่ เป็นหลัก ส่วนอาหารจริงๆ เป็นไอเดียได้ในบางส่วน ส่วน ใหญ่จะทำทานเองมากกว่า
                  </Typography>
                </Paper>
              </div>
            </div>
          </Slider>
        </Container>


      </Box>
      <div className="button-pass-box">
        {cardActivities && cardActivities.length > 0 && (
          <Link to="paypass_my">
            <button
              className="button_pink_outline"
              style={{
                width: 180,
                height: 44,
              }}
            >
              เข้าใช้งาน
            </button>
          </Link>
        )}
        <button className="button_pink_pass" style={{ marginTop: "8px" }} onClick={scrollToPricing}>สมัคร</button>
      </div>
    </div>
  );
}
