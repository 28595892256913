import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import { API } from "aws-amplify";

/* ACTION Section */

export const types = {
  SELECTED_CARD: "SELECTED_CARD",
  SELECTED_VALUE_REGISTER: "SELECTED_VALUE_REGISTER",
  SELECTED_CARD_ID: "SELECTED_CARD_ID",
  CLEAR_SELECTED_CARD: "CLEAR_SELECTED_CARD",
  CLEAR_STATUS: "CLEAR_STATUS",

  GET_PRODUCTS: "GET_PRODUCTS",
  GET_PRODUCTS_SUCCESS: "GET_PRODUCTS_SUCCESS",
  GET_CONTENTS: "GET_CONTENTS",
  GET_CONTENTS_SUCCESS: "GET_CONTENTS_SUCCESS",
  GET_PAGE: "GET_PAGE",
  GET_PAGE_SUCCESS: "GET_PAGE_SUCCESS",
  CLEAR_GET_PAGE: "CLEAR_GET_PAGE",
  CLEAR_GET_PAGE_SUCCESS: "CLEAR_GET_PAGE_SUCCESS",

  BUY_CARD_COLLECTION: "BUY_CARD_COLLECTION",
  BUY_CARD_COLLECTION_SUCCESS: "BUY_CARD_COLLECTION_SUCCESS",
  BUY_CARD_COLLECTION_FAIL: "BUY_CARD_COLLECTION_FAIL",
  BUY_VIDEO_PROGRAM: "BUY_VIDEO_PROGRAM",
  BUY_VIDEO_PROGRAM_SUCCESS: "BUY_VIDEO_PROGRAM_SUCCESS",
  BUY_VIDEO_PROGRAM_FAIL: "BUY_VIDEO_PROGRAM_FAIL",

  GET_CARD_COLLECTION: "GET_CARD_COLLECTION",
  GET_CARD_COLLECTION_SUCCESS: "GET_CARD_COLLECTION_SUCCESS",
  GET_CARD_COLLECTION_FAIL: "GET_CARD_COLLECTION_FAIL",
  GET_ALL_CARD_PROGRAM: "GET_ALL_CARD_PROGRAM",
  GET_ALL_CARD_PROGRAM_SUCCESS: "GET_ALL_CARD_PROGRAM_SUCCESS",
  GET_ALL_CARD_PROGRAM_FAIL: "GET_ALL_CARD_PROGRAM_FAIL",
  CLEAR_STATUS_CARD_ACTIVITIES: "CLEAR_STATUS_CARD_ACTIVITIES",

  UPDATE_PLAY_PASS_PLAY_TIME_VIDEO: "UPDATE_PLAY_PASS_PLAY_TIME_VIDEO",
  UPDATE_PLAY_PASS_PLAY_TIME_VIDEO_SUCCESS: "UPDATE_PLAY_PASS_PLAY_TIME_VIDEO_SUCCESS",
  UPDATE_PLAY_PASS_PLAY_TIME_VIDEO_FAIL: "UPDATE_PLAY_PASS_PLAY_TIME_VIDEO_FAIL",

  UPDATE_MODAL_DONE_CLICKED: "UPDATE_MODAL_DONE_CLICKED",
  UPDATE_MODAL_DONE_CLICKED_SUCCESS: "UPDATE_MODAL_DONE_CLICKED_SUCCESS",
  UPDATE_MODAL_DONE_CLICKED_FAIL: "UPDATE_MODAL_DONE_CLICKED_FAIL",
};

export const selectedValueRegister = (valueObject) => ({
  type: types.SELECTED_VALUE_REGISTER,
  payload: {
    valueObject,
  },
});

export const clearStatus = () => ({
  type: types.CLEAR_STATUS,
  payload: {},
});


export const clearStatusCardActivities = () => ({
  type: types.CLEAR_STATUS_CARD_ACTIVITIES,
  payload: {},
});

export const selectedCard = (index_card) => ({
  type: types.SELECTED_CARD,
  payload: { index_card },
});

export const selectedCardId = (card_id) => ({
  type: types.SELECTED_CARD_ID,
  payload: { card_id },
});

export const getVideoAllProgram = () => ({
  type: types.GET_ALL_CARD_PROGRAM,
  payload: {},
});

export const getCardActivities = (user_id) => ({
  type: types.GET_CARD_COLLECTION,
  payload: { user_id },
});

export const buyVideoProgramPynk = (user_id, card_id, index_card, video) => ({
  type: types.BUY_VIDEO_PROGRAM,
  payload: { user_id, card_id, index_card, video },
});

export const updatePlayPassPlayTimeVideo = (user_id, act_id, index_video) => ({
  type: types.UPDATE_PLAY_PASS_PLAY_TIME_VIDEO,
  payload: { user_id, act_id, index_video },
});

export const updateModalDoneClicked = (user_id, act_id) => ({
  type: types.UPDATE_MODAL_DONE_CLICKED,
  payload: { user_id, act_id },
});

export const buyCardCollectPoints = (
  user_id,
  price,
  number_of_days,
  pay_by
) => ({
  type: types.BUY_CARD_COLLECTION,
  payload: { user_id, price, number_of_days, pay_by },
});

export const getPage = (data) => ({
  type: types.GET_PAGE,
  payload: { data },
});

export const clearGetPage = () => ({
  type: types.CLEAR_GET_PAGE,
  payload: {},
});

export const getProducts = () => ({
  type: types.GET_PRODUCTS,
});

export const getContents = () => ({
  type: types.GET_CONTENTS,
});

const getVideoAllProgramSagaAsync = async () => {
  try {
    const apiResult = await API.get("pynk", "/getVideoAllProgram", {
      queryStringParameters: {},
    });
    return apiResult;
  } catch (error) {
    return { error, messsage: error.message };
  }
};

const getCardActivitiesSagaAsync = async (user_id) => {
  try {
    const apiResult = await API.get("pynk", "/getCardActivities", {
      queryStringParameters: {
        user_id,
      },
    });
    return apiResult;
  } catch (error) {
    return { error, messsage: error.message };
  }
};

const buyVideoProgramPynkSagaAsync = async (
  user_id,
  card_id,
  index_card,
  video
) => {
  try {
    const apiResult = await API.post("pynk", "/buyVideoProgramPynk", {
      body: {
        user_id,
        card_id,
        index_card,
        video,
      },
    });
    return apiResult;
  } catch (error) {
    return { error, messsage: error.message };
  }
};

const updatePlayPassPlayTimeVideoSagaAsync = async (
  user_id,
  act_id,
  index_video
) => {
  try {
    const apiResult = await API.put("pynk", "/updatePlayPassVideoPlayTime", {
      body: {
        user_id,
        act_id,
        index_video
      },
    });
    return apiResult;
  } catch (error) {
    return { error, messsage: error.message };
  }
};

const updateModalDoneClickedSagaAsync = async (
  user_id,
  act_id
) => {
  try {
    const apiResult = await API.put("pynk", "/modalDoneClicked", {
      body: {
        user_id,
        act_id
      },
    });
    return apiResult;
  } catch (error) {
    return { error, messsage: error.message };
  }
};

const buyCardCollectPointsSagaAsync = async (
  user_id,
  price,
  number_of_days,
  pay_by
) => {
  try {
    const apiResult = await API.post("pynk", "/buyCardCollectPoints", {
      body: {
        user_id,
        price,
        number_of_days,
        pay_by,
      },
    });
    return apiResult;
  } catch (error) {
    return { error, messsage: error.message };
  }
};

const getProductsSagaAsync = async () => {
  try {
    const apiResult = await API.get("pynk", "/getProducts", {
      queryStringParameters: {},
    });
    return apiResult;
  } catch (error) {
    return { error, messsage: error.message };
  }
};

const getContentsSagaAsync = async () => {
  try {
    const apiResult = await API.get("pynk", "/getContents", {
      queryStringParameters: {},
    });
    return apiResult;
  } catch (error) {
    return { error, messsage: error.message };
  }
};

function* getVideoAllProgramSaga({ }) {
  try {
    const apiResult = yield call(getVideoAllProgramSagaAsync);
    yield put({
      type: types.GET_ALL_CARD_PROGRAM_SUCCESS,
      payload: apiResult.results.result,
    });
  } catch (error) {
    return { error, messsage: error.message };
  }
}

function* getCardActivitiesSaga({ payload }) {
  const { user_id } = payload;
  try {
    const apiResult = yield call(getCardActivitiesSagaAsync, user_id);
    yield put({
      type: types.GET_CARD_COLLECTION_SUCCESS,
      payload: apiResult.results.result,
    });
  } catch (error) {
    console.log("error getCardActivitiesSaga: ", error);
    return { error, messsage: error.message };
  }
}

function* getProductsSaga({ }) {
  try {
    const apiResult = yield call(getProductsSagaAsync);
    yield put({
      type: types.GET_PRODUCTS_SUCCESS,
      payload: apiResult.results,
    });
  } catch (error) {
    console.log("error from getProductsSaga :", error);
  }
}

function* buyVideoProgramPynkSaga({ payload }) {
  const { user_id, card_id, index_card, video } = payload;
  try {
    yield call(
      buyVideoProgramPynkSagaAsync,
      user_id,
      card_id,
      index_card,
      video
    );
    yield put({
      type: types.BUY_VIDEO_PROGRAM_SUCCESS,
    });
  } catch (error) {
    return { error, messsage: error.message };
  }
}

function* updatePlayPassPlayTimeVideoSaga({ payload }) {
  const { user_id, act_id, index_video } = payload;
  try {
    yield call(
      updatePlayPassPlayTimeVideoSagaAsync,
      user_id,
      act_id,
      index_video
    );
    yield put({
      type: types.UPDATE_PLAY_PASS_PLAY_TIME_VIDEO_SUCCESS,
    });
  } catch (error) {
    return { error, messsage: error.message };
  }
}

function* updateModalDoneClickedSaga({ payload }) {
  const { user_id, act_id } = payload;
  try {
    yield call(
      updateModalDoneClickedSagaAsync,
      user_id,
      act_id
    );
    yield put({
      type: types.UPDATE_MODAL_DONE_CLICKED_SUCCESS,
    });
  } catch (error) {
    return { error, messsage: error.message };
  }
}

function* buyCardCollectPointsSaga({ payload }) {
  const { user_id, price, number_of_days, pay_by } = payload;
  try {
    const apiResult = yield call(
      buyCardCollectPointsSagaAsync,
      user_id,
      price,
      number_of_days,
      pay_by
    );
    if (apiResult.results.message == "success") {
      yield put({
        type: types.BUY_CARD_COLLECTION_SUCCESS,
      });
    } else if (apiResult.results.message == "fail") {
      yield put({
        type: types.BUY_CARD_COLLECTION_FAIL,
      });
    }
  } catch (error) {
    return { error, messsage: error.message };
  }
}

function* getContentsSaga({ }) {
  try {
    const apiResult = yield call(getContentsSagaAsync);
    yield put({
      type: types.GET_CONTENTS_SUCCESS,
      payload: apiResult.results,
    });
  } catch (error) {
    console.log("error from getContentsSaga :", error);
  }
}

export function* watchBuyVideoProgramPynkSaga() {
  yield takeEvery(types.BUY_VIDEO_PROGRAM, buyVideoProgramPynkSaga);
}

export function* watchGetVideoAllProgramSaga() {
  yield takeEvery(types.GET_ALL_CARD_PROGRAM, getVideoAllProgramSaga);
}

export function* watchGetCardActivitiesSaga() {
  yield takeEvery(types.GET_CARD_COLLECTION, getCardActivitiesSaga);
}

export function* watchBuyCardCollectPointsSaga() {
  yield takeEvery(types.BUY_CARD_COLLECTION, buyCardCollectPointsSaga);
}

export function* watchupdatePlayPassPlayTimeVideoSaga() {
  yield takeEvery(types.UPDATE_PLAY_PASS_PLAY_TIME_VIDEO, updatePlayPassPlayTimeVideoSaga);
}

export function* watchupdateModalDoneClickedSaga() {
  yield takeEvery(types.UPDATE_MODAL_DONE_CLICKED, updateModalDoneClickedSaga);
}

export function* watchGetProductsSaga() {
  yield takeEvery(types.GET_CONTENTS, getProductsSaga);
}

export function* watchGetContentsSaga() {
  yield takeEvery(types.GET_CONTENTS, getContentsSaga);
}
export function* saga() {
  yield all([
    fork(watchGetProductsSaga),
    fork(watchGetContentsSaga),
    fork(watchBuyCardCollectPointsSaga),
    fork(watchGetCardActivitiesSaga),
    fork(watchGetVideoAllProgramSaga),
    fork(watchBuyVideoProgramPynkSaga),
    fork(watchupdatePlayPassPlayTimeVideoSaga),
    fork(watchupdateModalDoneClickedSaga)
  ]);
}

const INIT_STATE = {
  products_pynk: null,
  contents_pynk: null,
  status_products_pynk: "default",
  status_contents_pynk: "default",
  dataPage: null,
  status_data_page: "default",
  status_buy_card_collection: "default",
  status_buy_video_program: "default",
  cardActivities: [],
  cardAllProgram: [],
  status_card_act: "default",
  status_card_all_program: "default",
  index_card: 0,
  card_id: 0,
  value_selected_regis: {},
  status_update_play_pass_play_time_video: "default",
  status_update_modal_done_clicked: "default",
};

export function reducer(state = INIT_STATE, action) {
  switch (action.type) {
    case types.GET_ALL_CARD_PROGRAM:
      return { ...state, status_card_all_program: "loading" };
    case types.GET_ALL_CARD_PROGRAM_SUCCESS:
      return {
        ...state,
        status_card_all_program: "success",
        cardAllProgram: action.payload,
      };
    case types.GET_ALL_CARD_PROGRAM_FAIL:
      return { ...state, status_card_all_program: "fail" };
    case types.GET_CARD_COLLECTION:
      return { ...state, status_card_act: "loading" };
    case types.GET_CARD_COLLECTION_SUCCESS:
      return {
        ...state,
        status_card_act: "success",
        cardActivities: action.payload,
      };
    case types.GET_CARD_COLLECTION_FAIL:
      return { ...state, status_card_act: "fail" };
    case types.BUY_CARD_COLLECTION:
      return { ...state, status_buy_card_collection: "loading" };
    case types.BUY_CARD_COLLECTION_SUCCESS:
      return { ...state, status_buy_card_collection: "success" };
    case types.BUY_CARD_COLLECTION_FAIL:
      return { ...state, status_buy_card_collection: "fail" };
    case types.BUY_VIDEO_PROGRAM:
      return { ...state, status_buy_video_program: "loading" };
    case types.BUY_VIDEO_PROGRAM_SUCCESS:
      return { ...state, status_buy_video_program: "success" };
    case types.BUY_VIDEO_PROGRAM_FAIL:
      return { ...state, status_buy_video_program: "fail" };
    case types.UPDATE_PLAY_PASS_PLAY_TIME_VIDEO:
      return { ...state, status_update_play_pass_play_time_video: "loading" };
    case types.UPDATE_PLAY_PASS_PLAY_TIME_VIDEO_SUCCESS:
      return { ...state, status_update_play_pass_play_time_video: "success" };
    case types.UPDATE_PLAY_PASS_PLAY_TIME_VIDEO_FAIL:
      return { ...state, status_update_play_pass_play_time_video: "fail" };
    case types.UPDATE_MODAL_DONE_CLICKED:
      return { ...state, status_update_modal_done_clicked: "loading" };
    case types.UPDATE_MODAL_DONE_CLICKED_SUCCESS:
      return { ...state, status_update_modal_done_clicked: "success" };
    case types.UPDATE_MODAL_DONE_CLICKED_FAIL:
      return { ...state, status_update_modal_done_clicked: "fail" };
    case types.GET_PRODUCTS:
      return {
        ...state,
        status_products_pynk: "loading",
      };
    case types.GET_CONTENTS:
      return {
        ...state,
        status_contents_pynk: "loading",
      };
    case types.GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        products_pynk: action.payload.products,
        status_products_pynk: "success",
      };
    case types.GET_CONTENTS_SUCCESS:
      return {
        ...state,
        contents_pynk: action.payload.contents,
        status_contents_pynk: "success",
      };
    case types.GET_PAGE:
      return {
        ...state,
        dataPage: action.payload.data,
        status_data_page: "success",
      };
    case types.CLEAR_GET_PAGE:
      return {
        ...state,
        status_data_page: "default",
      };
    case types.CLEAR_STATUS:
      return {
        ...state,
        status_buy_card_collection: "default",
        status_buy_video_program: "default",
      };
    case types.CLEAR_STATUS_CARD_ACTIVITIES:
      return {
        ...state,
        status_update_modal_done_clicked: "default",
        status_card_act: "default",
      };
    case types.SELECTED_VALUE_REGISTER:
      return {
        ...state,
        value_selected_regis: action.payload.valueObject,
      };
    case types.SELECTED_CARD:
      return { ...state, index_card: action.payload.index_card };
    case types.SELECTED_CARD_ID:
      return { ...state, card_id: action.payload.card_id };
    default:
      return { ...state };
  }
}
